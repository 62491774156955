import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import { FilterBy } from 'dto/material';
import SearchFilters from './SearchFilters';
import InputField from './content/InputField';
import SearchNav from './content/SearchNav';
import SearchMaterialItem from './SearchMaterialItem';
import SearchPackageItem from './SearchPackageItem';
import SearchMaterialSetItem from './SearchMaterialSetItem';
import MaterialContainerFlyout from './MaterialContainerFlyout';

import './SearchPanel.css';
import './SearchFlyout.css';
import PackageContainerFlyout from './PackageContainerFlyout';

const SearchPanel = observer(() => {
  const { searchStore } = useStores();
  const closePopUp = () => searchStore.closeSearchPanel();

  const onFilterSelect = (filterBy: FilterBy) => {
    searchStore.filterBy(filterBy);
  };

  return (
    <CSSTransition in={searchStore.isSearchPanelOpen} timeout={500} classNames="search-flyout" unmountOnExit>
      <>
        <div className="mat_suche_wrapper search-flyout-wrapper">
          <div className="search search-transparency">
            {/* Do not use loadingStore as it doesn't work with the computed searchResult without e.g. a useEffect or similar */}
            {searchStore.searchResults().isLoading && <div className="loading-bar loading_bar_top" />}
            <div className="w-layout-grid grid">
              <InputField />
              <SearchFilters onFilterSelect={onFilterSelect} />
              <div className="search-right-column" />
              <div className="div-block-33 search-results-grid">
                <div className="search_results">
                  {searchStore.searchResults().value.map(guideMaterial => {
                    if (guideMaterial.material) {
                      return <SearchMaterialItem material={guideMaterial.material} key={guideMaterial.material.materialId} />;
                    }
                    if (guideMaterial.package) {
                      return <SearchPackageItem packageItem={guideMaterial.package} key={guideMaterial.package.packageId} />;
                    }
                    if (guideMaterial.materialSet) {
                      return (
                        <SearchMaterialSetItem materialSet={guideMaterial.materialSet} key={guideMaterial.materialSet.materialSetId} />
                      );
                    }
                    return <></>;
                  })}
                </div>
              </div>
              <SearchNav closePopUp={closePopUp} />
            </div>
          </div>
        </div>
        <MaterialContainerFlyout />
        <PackageContainerFlyout />
      </>
    </CSSTransition>
  );
});

export default SearchPanel;

import Button from 'components/Form/Button';
import { TemplatePicture } from 'components/Picture';
import { SimplePackageWithPicturesDTO } from 'dto/package';
import { observer } from 'mobx-react';
import React from 'react';
import { SelectableType } from 'stores/searchStore';
import { useStores } from 'util/mobx/stores';
import { SimpleTemplateDTO } from '../../../../dto/template';

interface Props {
  pack: SimplePackageWithPicturesDTO;
  template: SimpleTemplateDTO;
}

const TemplateItem = observer(({ pack, template }: Props) => {
  const { searchStore } = useStores();

  const handleSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (searchStore.onSelect) {
      searchStore.onSelect({ pack });
    }
  };

  let showButton = false;

  if (searchStore.onSelect) {
    showButton = searchStore.selectableTypes.indexOf(SelectableType.Package) >= 0;
  }
  return (
    <>
      <div className="list_item_material">
        <div className="image_wrapper_50">
          <div className="image_border image-border" />
          <TemplatePicture />
        </div>
        <div className="material_info" style={{ paddingRight: '30px' }}>
          <div className="material_text no_hover">
            <div>{template.name}</div>
          </div>
          {showButton && (
            <div onClick={handleSelect}>
              <Button className="btn_small_flyout">
                <div className="txt_dropout_1">{searchStore.actionButtonLabel}</div>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default TemplateItem;

import { Type } from 'class-transformer';
import { IsOptional, ValidateNested } from 'class-validator';
import { BasicMaterialSetDTO } from './materialSet';
import { SimplePackageWithPicturesDTO } from './package';
import { SimpleTemplateDTO } from './template';

/**
 * PackageContainersDTO contains all associations that use a the package.
 * This includes the template and the materialSets.
 */
export class PackageContainersDTO {
  @ValidateNested()
  @Type(() => SimplePackageWithPicturesDTO)
  package!: SimplePackageWithPicturesDTO;

  @ValidateNested()
  @Type(() => SimpleTemplateDTO)
  template!: SimpleTemplateDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => BasicMaterialSetDTO)
  materialSets?: BasicMaterialSetDTO[];
}

import React from 'react';
import FlyoutContainer from 'components/FlyoutContainer';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import MaterialValueTags from 'components/MaterialValueTags';
import Picture from 'components/Picture';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { SetType, BasicMaterialSetDTO } from 'dto/materialSet';
import { isERP } from 'util/materialUtils';
import { SimplePackageDTO, PackagingType } from 'dto/package';
import MaterialContainerListItem from './MaterialContainerListItem';

const MaterialContainerFlyout = observer(() => {
  const { t } = useTranslation('materialContainerFlyout');
  const { searchStore } = useStores();

  const closePopUp = () => {
    searchStore.setIsMaterialContainerFlyoutOpen(false);
  };

  const item = searchStore.selectedSearchItem;

  const materialContainers = searchStore.selectedMaterialContainers;

  const sets: Record<string, BasicMaterialSetDTO[]> = {};

  if (materialContainers && Array.isArray(materialContainers.materialSets)) {
    sets.setTypeImplants = materialContainers.materialSets.filter(ms => ms.type === SetType.Implants);
    sets.setTypeSets = materialContainers.materialSets.filter(ms => ms.type === SetType.Sets);
    sets.setTypeMedicals = materialContainers.materialSets.filter(ms => ms.type === SetType.Medicals);
    sets.setTypeRentalMaterials = materialContainers.materialSets.filter(ms => ms.type === SetType.RentalMaterials);
  }

  const packages: Record<string, SimplePackageDTO[]> = {};

  if (materialContainers && Array.isArray(materialContainers.packages)) {
    packages.packagingTypeSingle = materialContainers.packages.filter(pack => pack.packagingType === PackagingType.Single);
    packages.packagingTypeMulti = materialContainers.packages.filter(pack => pack.packagingType === PackagingType.Multi);
  }

  return (
    <>
      <div className="ios-bar" />
      <FlyoutContainer
        icon={<img src="images/icon_import.jpg" width="50" alt="" className="image_circle_40" />}
        isOpen={searchStore.isMaterialContainerFlyoutOpen && searchStore.selectedMaterialContainers !== undefined}
        closePopUp={closePopUp}
        cancelLabel={t('button.close')}
        title={t('headline')}
        className="behind-drawer"
        iosMarginTop
      >
        <div>
          {item?.material && (
            <>
              <div className="list_item_material in_material_suche">
                <div className="image_wrapper_50">
                  <div className="image_border image-border" />
                  {item.material.picture ? (
                    <Picture src={item.material.pictureThumbnail} width={50} alt="" className="image_circle_50" />
                  ) : (
                    <GetCameraImageButton buttonStyle="icon" description="" picturesOnly />
                  )}
                </div>
                <div className="material_info in_material_suche">
                  <div className="material_text">{item.material.name}</div>
                  <MaterialValueTags material={item.material} />
                </div>
              </div>

              <div className="div-block-149">
                <div className="div-block-131 in_materialsearch">
                  {materialContainers && !materialContainers.packages && isERP(item.material) && (
                    <>
                      <div className="txt_chapter_flyout in_material_search">{t('subHeadline.materialErp')}</div>
                      <MaterialContainerListItem materialErp={item.material} />
                    </>
                  )}
                  {materialContainers && !materialContainers.packages && !isERP(item.material) && (
                    <>
                      <div className="txt_chapter_flyout in_material_search">{t('subHeadline.materialManual')}</div>
                      <MaterialContainerListItem materialManual={item.material} />
                    </>
                  )}

                  {Object.keys(packages).map(type => {
                    if (Array.isArray(packages[type]) && packages[type].length > 0) {
                      return (
                        <>
                          <div className="txt_chapter_flyout in_material_search">{t(`subHeadline.${type}`)}</div>
                          {packages[type].map(p => (
                            <MaterialContainerListItem package={p} key={p.packageId} />
                          ))}
                        </>
                      );
                    }
                    return <></>;
                  })}

                  {Object.keys(sets).map(type => {
                    if (Array.isArray(sets[type]) && sets[type].length > 0) {
                      return (
                        <>
                          <div className="txt_chapter_flyout in_material_search">{t(`subHeadline.${type}`)}</div>
                          {sets[type].map(ms => (
                            <MaterialContainerListItem materialSet={ms} key={ms.materialSetId} />
                          ))}
                        </>
                      );
                    }
                    return <></>;
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </FlyoutContainer>
    </>
  );
});

export default MaterialContainerFlyout;

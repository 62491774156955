import { useInvalidateAnyGuide, useInvalidateMaterialLike } from 'api/invalidate';
import { updateMaterialSet } from 'api/materialSet';
import { useGetMaterialSetLastCheck } from 'api/materialSetHooks';
import { useGetSetGroups } from 'api/setGroupHooks';
import Instructions from 'components/Instructions/Instructions';
import MaterialValueTags from 'components/MaterialValueTags/MaterialValueTags';
import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import React, { useCallback, useMemo, useState } from 'react';
import { LoadingType } from 'stores/loadingStore';
import { useStores } from 'util/mobx/stores';
import { ItemPicture } from '../../../material/ListItems';
import { GuideMaterialListItem } from '../GuideMaterialListItem';
import { GuideMaterialCheckPrefixRenderer } from '../checkRenderer';
import { SetGroupItem } from './SetGroupItem';

interface Props {
  guideMaterial: GuideMaterialLikeFlatDTO;
  onClick: () => void;
  onDelete?: () => void;
  onAddLotNumber?: () => void;
  CheckPrefix?: GuideMaterialCheckPrefixRenderer;
}

export const MaterialSetItem = ({ guideMaterial, onClick, onDelete, onAddLotNumber, CheckPrefix }: Props) => {
  if (!guideMaterial.materialSet) {
    throw new Error('the guideMaterial must have a materialSet');
  }
  const { loadingStore } = useStores();

  const materialSet = guideMaterial.materialSet;

  const invalidateGuide = useInvalidateAnyGuide();
  const invalidateMaterials = useInvalidateMaterialLike();

  const handleUpdateFile = useCallback(
    async (fileId: string) => {
      await loadingStore.withLoadingBar(() => updateMaterialSet({ materialSetId: materialSet.materialSetId, pictureFileId: fileId }));
      invalidateGuide();
      invalidateMaterials();
    },
    [invalidateGuide, invalidateMaterials, loadingStore, materialSet.materialSetId]
  );

  const [enabled, setEnabled] = useState(false);

  const handleExpand = useCallback(() => {
    setEnabled(true);
  }, []);

  const { data, isInitialLoading } = useGetSetGroups(materialSet.materialSetId, {
    enabled,
    loadingType: LoadingType.ONLY_LOADING
  });

  const setGroups = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.setGroups.map(setGroup => (
      <SetGroupItem
        key={setGroup.setGroupId}
        guideMaterial={guideMaterial}
        setGroup={setGroup}
        onAddLotNumber={onAddLotNumber}
        GroupMaterialPrefixRenderer={CheckPrefix}
      />
    ));
  }, [CheckPrefix, data, guideMaterial, onAddLotNumber]);

  const { data: lastCheck } = useGetMaterialSetLastCheck(materialSet.materialSetId, {
    loadingType: LoadingType.NONE
  });

  return (
    <GuideMaterialListItem
      level={0}
      classNamePrefix="set"
      isInitialLoading={isInitialLoading}
      onDropdownExpand={handleExpand}
      dropdownItems={setGroups}
      amount={guideMaterial.amount}
      name={materialSet.name}
      picture={
        <ItemPicture picture={materialSet} needsStorageLocation={!guideMaterial.storageLocationId} onUpdateFile={handleUpdateFile} />
      }
      onClick={onClick}
      instructions={<Instructions notes={guideMaterial.notes} />}
      end={<MaterialValueTags materialSet={materialSet} lastCheck={lastCheck} fullInfo />}
      prefix={CheckPrefix && <CheckPrefix guideMaterialId={guideMaterial.guideMaterialId} />}
      onOpenKnowledgeAndAttitudes={onClick}
      onDelete={onDelete}
      onAddLotNumber={onAddLotNumber}
    />
  );
};

import FlyoutContainer from 'components/FlyoutContainer';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { BasicMaterialSetDTO, SetType } from '../../../dto/materialSet';
import PackageItem from './PackageContainerListItem/PackageItem';
import SetItem from './PackageContainerListItem/SetItem';
import TemplateItem from './PackageContainerListItem/TemplateItem';

const MaterialContainerFlyout = observer(() => {
  const { t } = useTranslation('packageContainerFlyout');
  const { searchStore } = useStores();

  const closePopUp = () => {
    searchStore.setIsMaterialContainerFlyoutOpen(false);
  };

  const item = searchStore.selectedSearchItem;

  const packageContainers = searchStore.selectedPackageContainers;

  const sets: Record<string, BasicMaterialSetDTO[]> = {};

  if (packageContainers && Array.isArray(packageContainers.materialSets)) {
    sets.setTypeImplants = packageContainers.materialSets.filter(ms => ms.type === SetType.Implants);
    sets.setTypeSets = packageContainers.materialSets.filter(ms => ms.type === SetType.Sets);
    sets.setTypeMedicals = packageContainers.materialSets.filter(ms => ms.type === SetType.Medicals);
    sets.setTypeRentalMaterials = packageContainers.materialSets.filter(ms => ms.type === SetType.RentalMaterials);
  }

  return (
    <>
      <div className="ios-bar" />
      <FlyoutContainer
        icon={<img src="images/icon_import.jpg" width="50" alt="" className="image_circle_40" />}
        isOpen={searchStore.isMaterialContainerFlyoutOpen && searchStore.selectedPackageContainers !== undefined}
        closePopUp={closePopUp}
        cancelLabel={t('button.close')}
        title={t('headline')}
        className="behind-drawer"
        iosMarginTop
      >
        <div>
          {item?.package && packageContainers && (
            <>
              <div className="list_item_material in_material_suche">
                <PackageItem pack={item.package} />
              </div>

              <div className="div-block-149">
                <div className="div-block-131 in_materialsearch">
                  <>
                    <div className="txt_chapter_flyout in_material_search">{t('subHeadline.template')}</div>
                    <TemplateItem pack={packageContainers.package} template={packageContainers.template} />
                  </>

                  {Object.keys(sets).map(type => {
                    if (Array.isArray(sets[type]) && sets[type].length > 0) {
                      return (
                        <>
                          <div className="txt_chapter_flyout in_material_search">{t(`subHeadline.${type}`)}</div>
                          {sets[type].map(ms => (
                            <SetItem materialSet={ms} key={ms.materialSetId} />
                          ))}
                        </>
                      );
                    }
                    return <></>;
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </FlyoutContainer>
    </>
  );
});

export default MaterialContainerFlyout;

import { subject } from '@casl/ability';
import { useInvalidateGroupMaterials } from 'api/invalidate';
import { Can, actions, subjectArea } from 'casl/setupCaslAbility';
import InfoBox from 'components/InfoBox';
import ListItemAdd from 'components/ListItemAdd';
import { GroupMaterialsDTO } from 'dto/groupMaterial';
import { PackagingType } from 'dto/package';
import { GroupMaterialListItemMenu, getGroupMaterialTemplateMenuRenderer } from 'modules/materialSet/Menu/GroupMaterialListItemMenu';
import { useMaterialSetListContext } from 'modules/materialSet/materialSetListContext';
import React, { useCallback } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { AnyMaterial, SelectableType } from 'stores/searchStore';
import { getItemStyle } from 'util/dragAndDrop';
import { useStores } from 'util/mobx/stores';
import { MultiTemplateItem } from '../Instrument/MultiTemplateItem';
import { SingleTemplateItem } from '../Instrument/SingleTemplateItem';
import { MaterialItem } from '../Material/MaterialItem';

const EMPTY: GroupMaterialsDTO = {
  groupMaterials: [],
  total: 0
};

interface Props {
  setGroupId: string;
  groupType: string;
  groupMaterials?: GroupMaterialsDTO;
  level?: number;
}

export const GroupMaterialList = ({ level = 1, setGroupId, groupType, groupMaterials = EMPTY }: Props) => {
  const { onReload, checksEnabled, readonly } = useMaterialSetListContext();

  const { domainStore, searchStore, materialSetsStore } = useStores();
  const { t: materialSetTranslation } = useTranslation(groupType);

  const { t: searchTranslation } = useTranslation('materialSearch');

  const invalidateGroupMaterials = useInvalidateGroupMaterials();

  const onGroupMaterialAdd = useCallback(
    async (m: AnyMaterial) => {
      const groupMaterial = await materialSetsStore.addMaterialToMaterialSet(m, setGroupId);
      if (groupMaterial) {
        await invalidateGroupMaterials();
      }
    },
    [invalidateGroupMaterials, materialSetsStore, setGroupId]
  );

  const handleCreateGroupMaterials = useCallback(() => {
    searchStore.openSearchPanel(
      m => onGroupMaterialAdd(m),
      searchTranslation('searchItem.addItem'),
      SelectableType.Material,
      SelectableType.Package
    );
  }, [onGroupMaterialAdd, searchStore, searchTranslation]);

  return (
    <>
      {!checksEnabled && !readonly ? (
        <Can I={actions.add} this={subject(subjectArea.groupMaterial, { departmentId: domainStore.currentDepartment.id })}>
          <ListItemAdd marginLeft={82} className="list_item_material_add in_content" onClick={handleCreateGroupMaterials}>
            <InfoBox label={materialSetTranslation('groupsList.button.createGroupMaterials')} />
          </ListItemAdd>
        </Can>
      ) : null}
      <Droppable droppableId={setGroupId} type="groupItem">
        {provided => (
          <>
            <div ref={provided.innerRef}>
              <>
                {groupMaterials.groupMaterials.map((groupMaterial, index) => {
                  let item = <div>NOT IMPLEMENTED</div>;
                  if (groupMaterial.material) {
                    item = (
                      <MaterialItem
                        level={level}
                        setGroupId={setGroupId}
                        groupMaterial={groupMaterial}
                        menu={<GroupMaterialListItemMenu setGroupId={setGroupId} groupMaterial={groupMaterial} specificId={{}} />}
                        onReload={onReload}
                      />
                    );
                  } else if (groupMaterial.template?.packagingType === PackagingType.Multi) {
                    item = (
                      <MultiTemplateItem
                        level={level}
                        setGroupId={setGroupId}
                        groupMaterial={groupMaterial}
                        menu={getGroupMaterialTemplateMenuRenderer(setGroupId)}
                      />
                    );
                  } else if (groupMaterial.template?.packagingType === PackagingType.Single) {
                    item = (
                      <SingleTemplateItem
                        level={level}
                        setGroupId={setGroupId}
                        groupMaterial={groupMaterial}
                        menu={getGroupMaterialTemplateMenuRenderer(setGroupId)}
                      />
                    );
                  }

                  return (
                    <Draggable
                      index={index}
                      draggableId={groupMaterial.groupMaterialId}
                      key={groupMaterial.groupMaterialId}
                      isDragDisabled={!!checksEnabled || readonly}
                    >
                      {(draggableProvided, snapshot) => {
                        const style = getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style) as { top: number };

                        if (snapshot.isDragging) {
                          // Hacky Hack https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-695772970
                          style.top -= (draggableProvided.draggableProps.style as { height: number })?.height || 0;
                        }
                        return (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={style}
                          >
                            {item}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </>
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </>
  );
};

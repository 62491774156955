import { getPackage } from 'api/package';
import Picture from 'components/Picture';
import { SimplePackageWithPicturesDTO } from 'dto/package';
import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from 'util/mobx/stores';

interface Props {
  pack: SimplePackageWithPicturesDTO;
}

const PackageItem = observer(({ pack }: Props) => {
  const { guideDetailDrawerStore } = useStores();

  const handleShowDetailDrawerClick = async () => {
    const fullPackage = await getPackage(pack.packageId);
    guideDetailDrawerStore.setSelectedPackage(fullPackage.pack);

    guideDetailDrawerStore.setIsOpen(true);
  };

  return (
    <>
      <div className="list_item_material" onClick={handleShowDetailDrawerClick}>
        <div className="image_wrapper_50">
          <div className="image_border image-border" />
          <Picture src={pack.picture} width="50" alt="" className="image_circle_50" />
        </div>
        <div className="material_info">
          <div className="material_text">
            <div>{pack.name}</div>
          </div>

          <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />
        </div>
      </div>
    </>
  );
});

export default PackageItem;
